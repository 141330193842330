import React from "react";
import resumeFile from "../documents/resume.pdf";
const AboutUs = ({ classicHeader, darkTheme }) => {
  return (
    <section id="about" className={"section " + (darkTheme ? "bg-dark-1" : "")}>
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            -
          </h2>
         
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
           Ben Kimim?
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        <div className="row gy-5">
          {/* About me content start */}
          <div className="col-lg-7 col-xl-8 text-center text-lg-start">
            <h2
              className={
                "text-7 fw-600 mb-3 " + (darkTheme ? "text-white" : "")
              }
            >
             Merhaba! <span className="text-primary"> Ben İbrahim👋</span> 
            </h2>
            <p className={darkTheme ? "text-white-50" : ""}>
            E-ticaret yolculuğuma 2018 yılında Türkiye'nin Denizli şehrinde adım attım ve o günden bu yana sürekli olarak büyüyen, gelişen bir serüvenin içinde yer aldım. İlk başlarda yerel çapta başlayan bu girişim, kısa sürede sınırları aşıp Londra ve Belgrad gibi Avrupa'nın önemli şehirlerine kadar yayıldı. 6 yılı aşkın süredir e-ticaret sektöründe yer alıyorum ve bu süreçte özellikle Etsy platformunda birçok farklı müşteriyle çalışarak on binlerce satış gerçekleştirme başarısı elde ettim. Etsy'de kazandığım bu deneyim, platformun dinamiklerini anlamamı ve başarılı bir strateji geliştirmemi sağladı. Şu an aktif olarak Etsy üzerinde satış yapmaya devam ediyor ve bu platformda elde ettiğim başarıların sırlarını danışmanlık hizmetimle başkalarıyla paylaşmayı sürdürüyorum. Her gün e-ticaretin sağladığı fırsatlardan faydalanarak büyüyen başarı hikayelerinin bir parçası olan bir profesyonel olarak, benimle çalışmayı tercih eden müşterilerime aynı başarıyı kazandırmak adına titizlikle çalışıyorum. E-ticaretin gücüne inanıyor ve herkesin bu dünyada kendine yer bulabileceğine olan inancımı danışmanlık hizmetimle ortaya koyuyorum. Siz de bu başarı hikayesine dahil olmak isterseniz, birlikte çalışmak için buradayım!
 
            </p>
            <p className={darkTheme ? "text-white-50" : ""}>
               
            </p>
          </div>
          {/* About me content end */}
          {/* about me personal detials start */}
          <div className="col-lg-5 col-xl-4">
            <div className="ps-lg-4">
              <ul
                className={
                  "list-style-2 " +
                  (darkTheme ? "list-style-light text-light" : "")
                }
              >
                <li>
                <span className="bg-dark-2 rounded-pill p-2 mb-lg-1 d-none d-lg-inline-block">
              <img
                className="img-fluid rounded-pill d-block"
                src="images/ibo3.png"
                title="İbrahim AKÇİL"
                alt="profile"
              />
            </span>
                </li>
                {/* <li>
                  <span className="fw-600 me-2">Email:</span>
                  <a href="mailto:chat@simone.com">chat@simone.com</a>
                </li> */}
                {/* <li>
                  <span className="fw-600 me-2">Age:</span>28
                </li>
                <li className="border-0">
                  <span className="fw-600 me-2">From:</span>Los Angeles,
                  California
                </li> */}
              </ul>
              {/* <a
                href={resumeFile}
                download
                className="btn btn-primary rounded-pill"
              >
                Download CV
              </a> */}
            </div>
          </div>
          {/* about me personal details end */}
        </div>
        {/* projects rewards counting start */}
        <div
          className={
            "brands-grid separator-border mt-5 " +
            (darkTheme ? "separator-border-light" : "")
          }
        >
          <div className="row">
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>5</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                Milyon$ Ciro
                </p>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>90</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                Başarılı Danışan
                </p>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>100</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                Başarılı Mağaza
                </p>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>500.000</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                Adet Sipariş
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* projects rewards counting end */}
      </div>
    </section>
  );
};

export default AboutUs;
