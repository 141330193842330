import { auto } from "@popperjs/core";
import React, { useEffect, useRef } from "react";

import Slider from "react-slick";
const MadeSlider = ({ classicHeader, darkTheme}) => {
    const sliderRef = useRef();

    const images = [
        // { src: "images/projects/project-1.jpg" },
        // { src: "images/projects/project-2.jpg" },
        // { src: "images/projects/project-3.jpg" },
        // { src: "images/projects/project-4.jpg" },
        // { src: "images/projects/slider.png" },
       
         { src: "images/projects/slider3.jpeg" },
         { src: "images/projects/slider4.jpeg" },
        { src: "images/projects/slider5.png" },
        { src: "images/projects/slider7.jpg" },
        { src: "images/projects/slider8.jpg" },
    ];
    var settings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 3000,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                },
            },
        ],
    };
    return (
        <>
            <section
                id="madeslider"
                className={"section " + (darkTheme ? "bg-dark-1" : "bg-light")}
            >
                <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
                {/* <div className="row"> */}
                <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-white-50  opacity-1" : "text-light  opacity-4")
            }
          >
            -
          </h2>
          <p
            className={
              "text-9  fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            Yaptıklarımız
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
                <div className="col-md-12">
                    <Slider {...settings}>
                      
                      {images?.length > 0 &&
                        images?.map((image, index) => (
                            <div className="col-md-12">
                            <img
                              className="img-fluid rounded mx-auto d-block"
                              src={image.src}
                              alt=""
                            />
                            <p className="ms-3 mb-0">
                              <strong
                                className={
                                  "d-block fw-600 " +
                                  (darkTheme ? "text-white" : "text-dark")
                                }
                              >
                                
                              </strong>
                              <span className="text-muted fw-500">
                                {" "}
                                
                              </span>
                            </p>
                          </div>
                        ))}
                    </Slider>
                    </div>
                    </div>
                    {/* </div> */}
            </section>
        </>
    )

};
export default MadeSlider;